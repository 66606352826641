<template>
  <carousel :items-to-show="2.9" :wrap-around="true" :autoplay="4000" class="noHandy">
    <slide v-for="slide in slides" :key="slide">
      <router-link to="/Leistungen">
        <img :src="getImgUrl(slide)" v-bind:alt="pic" v-bind:height=(windowWidth/6.2)
        class="p-1" style="background-color: white;">
      </router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>

  <carousel :items-to-show="1" :wrap-around="true" :autoplay="4000" class="noDesktop">
    <slide v-for="slide in slides" :key="slide">
      <router-link to="/Leistungen">
        <img
          :src="getImgUrl(slide)"
          v-bind:alt="pic"
          v-bind:height="290"
          class="p-1"
          style="background-color: white"
        />
      </router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
//import "vue3-carousel/dist/carousel.css";
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  data() {
    const slides = [
      "b1",
      "b2",
      "b3",
      "b4",
      "b6",
      "b7",
      "b8",
      "b9",
      "b10",
      "b11",
      "b12",
      "b13",
    ];
    return {
      slides,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmouned() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
