<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground pb-5" id="top">
          <div class="row pt-2 mb-0 mt-0 justify-content-center">
            <div class="col-md-10 mt-5 mb-5 title grauHellfeld">
              <div class="row">
                <div class="col-12 fw-bold text-center rot p-4">
                  <img src="../assets/handyman.jpg" style="width: 60%" p-0 />
                </div>
              </div>

              <div class="row">
                <div class="row mt-3 mb-3 justify-content-center">
                  <div class="col-sm-10 col-md-10 pt-3">
                    <h1 class="pb-0 text-center rotSgrau">
                      Wir sind ein modernes und wachsendes Unternehmen <br />aus Bad
                      Blankenburg
                    </h1>

                    <h2 class="pb-5 text-center">
                      und bieten ab sofort folgende Stellen an:
                    </h2>
                    <div class="row pb-5 text-center justify-content-center fw-bold">
                      <!-- <div class="col bg-white border p-3 m-2 hoverBig">
                        <a href="#cnc" class="h5AnkerLink">
                          <div class="p-0 m-0">CNC Fräser / CNC Dreher</div>
                        </a>
                      </div>                       -->

                      <div class="col bg-white border p-3 m-2 hoverBig">
                        <a href="#ausbildung" class="h5AnkerLink">
                          <div>Ausbildung zum Zerspanungs-Mechaniker/in</div>
                        </a>
                      </div>
                      <div class="col bg-white border p-3 m-2 hoverBig">
                        <a href="#praktikum" class="h5AnkerLink">
                          <div>Praktikumsplatz</div>
                        </a>
                      </div>
                    </div>
                    <div class="row pb-5 justify-content-center"></div>
                  </div>
                </div>
              </div>
              <div
                class="row mt-3 mb-5 justify-content-center"
                style="z-index: 9"
                id="cnc"
              >
                <div class="col-sm-10 col-md-10 p-5 border bg-white border-3">
                  <p>
                    <b>Unseren Mitarbeitern bieten wir:</b>
                  </p>
                  <ul class="ps-3">
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />abwechslungsreiche und anspruchsvolle Tätigkeit
                    </li>
                    <li>
                      <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />ein
                      unbefristetes Arbeitsverhältnis
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />leistungsgerechte Vergütung
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />sicheres monatliches Einkommen
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Weihnachtsgeld
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />kostenfreie Weiterbildungsmöglichkeit
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />kostenfreie Stellung von Arbeitskleidung
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />kostenfreie Parkplätze
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Bikeleasing auch für Privat
                    </li>
                    <li>
                      <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />Eine
                      vielseitige, abwechslungsreiche Tätigkeit an modernsten
                      Bearbeitungszentren
                    </li>
                    <li>
                      <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />ein
                      freundliches Betriebsklima, geprägt von Vertrauen, Respekt und
                      Fairness
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="row mt-3 mb-5 justify-content-center" style="z-index: 9;" id="cnc">
                <div class="col-sm-10 col-md-10 p-5 border bg-white border-3">
                  <h2 class="rotSgrau fw-bolder pb-4">
                    CNC Fräser / CNC Dreher (m, w, d)
                  </h2>

                  <p>
                    Das primäre Stellenziel besteht in der fachgerechten,
                    qualitätsgerechten und den Anforderungen entsprechenden
                    Produktion.
                  </p>
                  <h5>Zu Ihren Aufgaben gehören:</h5>
                  <ul>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Rüsten und Einrichten unserer CNC Fräs- und
                      Drehmaschinen
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Drehen und Fräsen von Einzelteilen / Prototypen nach
                      technischer Zeichnungsvorgabe
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Eigenständiges Programmieren (Heidenhain, Fanuc,
                      Siemens)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Durchführen von Programmkorrekturen / Aktualisierungen
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Eigenständige Kontrolle der gefertigten Bauteile auf
                      Zeichnungskonformität
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Wartung und Pflege der Maschinen
                    </li>
                  </ul>

                  <h5>Was Sie mitbringen sollten:</h5>
                  <ul>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Ausbildung im Metallberuf mit CNC im Dreh oder
                      Fräsbereich mit Programmierkenntnissen (Heidenhain, Fanuc
                      oder Siemens)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Selbstständige, vorausschauende und eigenmotivierte
                      Arbeitsweise
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Programmierkenntnisse mit Heidenhain, Fanuc und/oder
                      Siemens
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Idealerweise Erfahrung in der Einzelteilfertigung
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Ausgeprägtes Qualitätsbewusstsein
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Freundliches Auftreten
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Zuverlässigkeit / Teamfähigkeit
                    </li>
                  </ul>
                  <h5>Was wir Ihnen bieten:</h5>
                  <ul>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Motivierte freundliche Kollegen in einem
                      teamorientierten Arbeitsumfeld
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Eine vielseitige, abwechslungsreiche Tätigkeit
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Entwicklungsmöglichkeiten in einem modernen und
                      dynamischen Umfeld
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Kostenlose Parkplätze
                    </li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>

                  <p>
                    <br />
                    Wenn wir Ihr Interesse geweckt haben, melden Sie sich
                    telefonisch oder schriftlich bei uns.<br />
                    <b
                      >Sie können auch gerne unsere
                      <a href="#onlinebewerbung" class="h5AnkerLink"
                        >Onlinebewerbung</a
                      >
                      nutzen.</b
                    ><br /><br />
                    Wir vereinbaren dann ein persönliches Gespräch zum
                    Kennenlernen.
                  </p>
                </div>
              </div> -->

              <div class="row mt-5 mb-5 justify-content-center" id="ausbildung">
                <div class="col-sm-12 col-md-10 p-5 bg-white border border-3">
                  <h2 class="rotSgrau fw-bolder pb-4">
                    Auszubildende für den Beruf Zerspanungsmechaniker*in (m/w/d)
                  </h2>
                  <p>
                    Wir sind ein stetig wachsendes Unternehmen mit Sitz in Bad
                    Blankenburg.
                  </p>
                  <p>
                    Wir suchen stetig motivierte und interessierte Auszubildende (m/w/d)
                    für die Ausbildung zum/zur Zerspanungsmechaniker/in.
                  </p>
                  <p>
                    Nach Beendigung ihrer Ausbildung verfügen Sie über einen umfangreichen
                    Wissensschatz in Programmierung, rüsten und bedienen von CNC-Dreh und
                    Fräsmaschinen. Im Rahmen der Ausbildung vermitteln wir Ihnen fundierte
                    Kenntnisse und Fertigkeiten in Ihrem Ausbildungsberuf. Als
                    Zerspanungsmechaniker (m/w/d) fertigen Sie Präzisionsbauteile aus
                    Metall durch spanende Verfahren wie Drehen, Fräsen, Bohren oder
                    Schleifen. Dabei arbeiten Sie an modernen CNC-Werkzeugmaschinen. Diese
                    richten Sie ein und überwachen den Fertigungsprozess.
                  </p>
                  <p>Die Ausbildungsdauer beträgt 3,5 Jahre.</p>
                  <h5>Ihr Profil:</h5>
                  <ul>
                    <li>
                      <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />
                      Sie verfügen über einen guten Realschulabschluss
                    </li>
                    <li>
                      <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />
                      Sie bringen technisches Verständnis und handwerkliches Geschick mit
                    </li>
                    <li>
                      <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />
                      Sie haben Spaß und Interesse im Umgang mit technischen Geräten,
                      Maschinen, Anlagen und Metall
                    </li>
                  </ul>

                  <p>
                    Sie interessieren sich für diese Ausbildungsstelle, sind sich Ihrer
                    Berufswahl noch nicht sicher? <br />
                    Gerne können Sie einmal in den Beruf bei uns reinschnuppern und ein
                    Praktikum bei uns machen.
                  </p>

                  <p>
                    <br />
                    Wenn wir Ihr Interesse geweckt haben, melden Sie sich telefonisch oder
                    schriftlich bei uns.<br />
                    <b
                      >Sie können auch gerne unsere
                      <a href="#onlinebewerbung" class="h5AnkerLink">Onlinebewerbung</a>
                      nutzen.</b
                    ><br /><br />
                    Wir vereinbaren dann ein persönliches Gespräch zum Kennenlernen.
                  </p>
                </div>
              </div>

              <div class="row mt-3 mb-5 justify-content-center" id="praktikum">
                <div class="col-sm-12 col-md-10 p-5 bg-white border border-3">
                  <h2 class="rotSgrau fw-bolder pb-4">Neugierig auf den Beruf?</h2>
                  <h3 class="fw-bolder pb-4">Praktikum jederzeit möglich</h3>
                  <p class="pt-4">
                    <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />Arbeiten
                    mit verschiedenen Metallen faszienieren Sie? Es macht Sie stolz etwas
                    herstellen zu können?
                  </p>
                  <p>
                    <img
                      src="../assets/Kreuz.png"
                      class="Kreuzpunkt"
                      alt="..."
                    />Hochmoderne CNC-Maschinen können Sie begeistern?
                  </p>
                  <p>
                    <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />
                    Die oben gelesenen Tätigkeiten sind genau das, was Sie gerne machen
                    möchten?
                  </p>
                  <p>
                    <img src="../assets/Kreuz.png" class="Kreuzpunkt" alt="..." />Sie sind
                    neugierig etwas zu lernen und haben eine motivierte und
                    qualitätsorientierte Arbeitsweise?
                  </p>
                  <p>Dann sollten wir uns unbedingt einmal kennen lernen.</p>
                  <p>
                    <br />
                    <b
                      >Sie können gerne unsere
                      <a href="#onlinebewerbung" class="h5AnkerLink">Onlinebewerbung</a>
                      nutzen.</b
                    ><br /><br />
                    Wir vereinbaren dann ein persönliches Gespräch zum Kennenlernen.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-2 mb-0 mt-0 justify-content-center" id="onlinebewerbung">
            <div class="col-md-10 title grauHellfeld" id="be">
              <div class="row">
                <div class="col-12">
                  <div class="title">
                    <h1 class="rotSgrauBig fw-bolder pb-4" style="text-align: center">
                      Online Bewerbung
                    </h1>
                    <h5 style="text-align: center">Wir freuen uns auf Ihre Bewerbung</h5>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10">
                  <div class="titleKlein">
                    <h5 class="p-2">Persönliches</h5>
                  </div>
                </div>
              </div>

              <div class="row mb-3 justify-content-center">
                <div class="col-md-10">
                  <label for="job" class="form-label">Stelle</label>
                  <ul>                    
                    <!-- <li class="kontaktLi">
                      <input
                        type="radio"
                        id="CNC_Fraeser"
                        name="customRadio"
                        class="custom-control-input"
                        v-model="bewerbung.Stelle"
                        value="vk"
                      />
                      <label class="custom-control-label" for="CNC_Fraeser"
                        >CNC Fräser / CNC Dreher (m/w/d)</label
                      >
                    </li> -->
                    <!-- <li class="kontaktLi">
                      <input
                        type="radio"
                        id="MA_QA"
                        name="customRadio"
                        class="custom-control-input"
                        v-model="bewerbung.Stelle"
                        value="ek"
                      />
                      <label class="custom-control-label" for="ek"
                        >Mitarbeiter für Qualitätssicherung CNC (m/w/d)</label
                      >
                    </li> -->
                    <li class="kontaktLi">
                      <input
                        type="radio"
                        id="ek"
                        name="customRadio"
                        class="custom-control-input"
                        v-model="bewerbung.Stelle"
                        value="ek"
                      />
                      <label class="custom-control-label" for="ek">
                        Auszubildende (m/w/d)</label
                      >
                    </li>
                    <li class="kontaktLi">
                      <input
                        type="radio"
                        id="Praktikant"
                        name="customRadio"
                        class="custom-control-input"
                        v-model="bewerbung.Stelle"
                        value="ek"
                      />
                      <label class="custom-control-label" for="Praktikant"
                        >Praktikum</label
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row mb-3 justify-content-center">
                <div class="col-md-5">
                  <label for="anrede" class="form-label">Anrede</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="bewerbung.Anrede"
                  >
                    <option value="ohne" selected>Bitte auswählen</option>
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                    <option value="Neutrale Anrede">Neutrale Anrede</option>
                  </select>
                </div>
                <div class="col-md-5"></div>
              </div>
              <div class="row mb-3 justify-content-center">
                <div class="col-md-5">
                  <label for="vname" class="form-label">Vorname</label>
                  <input
                    type="text"
                    class="form-control"
                    id="vname"
                    v-model="bewerbung.Vorname"
                  />
                  <label class="form-check-label inputerror" v-if="error.Vorname == true">
                    {{ error.VornameText }}
                  </label>
                </div>
                <div class="col-md-5">
                  <label for="nname" class="form-label">Nachname*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nname"
                    v-model="bewerbung.Nachname"
                    v-bind:class="[error.Nachname == true ? 'bg-warning' : '']"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Nachname == true"
                  >
                    {{ error.NachnameText }}
                  </label>
                </div>
              </div>
              <div class="row mb-3 justify-content-center">
                <div class="col-md-5">
                  <label for="email" class="form-label">E-mail*</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    v-model="bewerbung.Email1"
                    v-bind:class="[error.Email1 == true ? 'bg-warning' : '']"
                  />
                  <label class="form-check-label inputerror" v-if="error.Email1 == true">
                    {{ error.Email1Text }}
                  </label>
                </div>
                <div class="col-md-5">
                  <label for="telefon" class="form-label">Telefon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefon"
                    v-model="bewerbung.Telefon"
                  />
                </div>
              </div>
              <div class="row mb-3 justify-content-center">
                <div class="col-md-5">
                  <label for="strasse" class="form-label">Straße</label>
                  <input
                    type="text"
                    class="form-control"
                    id="strasse"
                    v-model="bewerbung.Strasse"
                  />
                </div>
                <div class="col-md-5">
                  <label for="hausnummer" class="form-label">Hausnummer</label>
                  <input
                    type="text"
                    class="form-control"
                    id="hausnummer"
                    v-model="bewerbung.Hausnummer"
                  />
                </div>
              </div>

              <div class="row mb-3 justify-content-center">
                <div class="col-md-5">
                  <label for="plz" class="form-label">Postleitzahl</label>
                  <input
                    type="text"
                    class="form-control"
                    id="plz"
                    v-model="bewerbung.PLZ"
                  />
                </div>
                <div class="col-md-5">
                  <label for="stadt" class="form-label">Stadt</label>
                  <input
                    type="text"
                    class="form-control"
                    id="stadt"
                    v-model="bewerbung.Stadt"
                  />
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="bewerbung.andere"
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich bin damit einverstanden, dass meine Bewerbung auch für
                    andere Stellen berücksichtigt wird.<br />
                    Natürlich werden Ihre Daten vertraulich bei uns behandelt.
                  </label>
                </div>
              </div>

              <div class="row mt-5 justify-content-center">
                <div class="col-md-10">
                  <div class="titleKlein">
                    <h5 class="p-2">
                      Wenn Sie möchten senden Sie uns ein Anschreiben und/oder einen
                      Lebenslauf?
                    </h5>
                  </div>
                </div>
              </div>

              <div class="row mb-3 justify-content-center">
                <div class="col-md-5">
                  <label for="anschreiben" class="form-label">Anschreiben</label>
                  <input
                    type="file"
                    ref="anschreibenfile"
                    name="anschreiben"
                    class="form-control"
                    id="anschreiben"
                    @change="uploadAnschreiben()"
                  />
                  <label class="form-check-label inputok">
                    {{ bewerbung.Anschreiben }}
                  </label>
                </div>

                <div class="col-md-5">
                  <label for="lebenslauf" class="form-label">Lebenslauf</label>
                  <input
                    type="file"
                    ref="lebenslauffile"
                    name="lebenslauf"
                    class="form-control"
                    id="lebenslauf"
                    @change="uploadLebenslauf()"
                  />
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Lebenslauf == true"
                  >
                    {{ error.LebenslaufText }}
                  </label>
                  <label class="form-check-label inputok">
                    {{ bewerbung.Lebenslauf }}
                  </label>
                </div>
              </div>

              <div class="row mt-5 justify-content-center">
                <div class="col-md-10">
                  <div class="titleKlein">
                    <h5 class="p-2">Datenschutzerklärung</h5>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-10">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="bewerbung.Datenschutz"
                    v-bind:class="[error.Datenschutz == true ? 'bg-warning' : '']"
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich habe die
                    <a class="linkSchwarz" href="/Datenschutz">Datenschutzerklärung</a>
                    zur Kenntnis genommen.
                  </label>
                  <label
                    class="form-check-label inputerror"
                    v-if="error.Datenschutz == true"
                  >
                    {{ error.DatenschutzText }}
                  </label>
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10">
                  {{ mail.gesendet }}
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-md-10">
                  <div class="d-grid">
                    <button
                      class="btn btn-outline-danger btn-lg bg-main"
                      @click="checkForm()"
                    >
                      Bewerbung abschicken
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#top" class="stickyPfeil">
          <i class="fas fa-angle-double-up"></i>
        </a>
      </div>
    </template>
  </Layout>
</template>

<script>
const axiosConfig = {
  crossDomain: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

function validEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
import Layout from "@/layouts/Layout";
import axios from "axios";

export default {
  name: "AktuellesStell",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
      bewerbung: {
        Stelle: "",
        Anrede: "",
        Vorname: "",
        Nachname: "",
        Email1: "",
        Strasse: "",
        Hausnummer: "",
        PLZ: "",
        Stadt: "",
        Telefon: "",
        andere: true,

        Lebenslauf: "",
        Lebenslauf_temp: "",
        Anschreiben: "",
        Anschreiben_temp: "",

        Datenschutz: false,
      },
      error: {
        Vorname: false,
        Nachname: false,
        Email1: false,
        Datenschutz: false,
        VornameText: "",
        NachnameText: "",
        Email1Text: "",
        DatenschutzText: "",
      },
      mail: {
        gesendet: "",
      },
      errors: 0,
    };
  },
  components: {
    Layout,
  },
  methods: {
    checkForm: function () {
      this.errors = 0;

      if (this.bewerbung.Nachname == "") {
        this.error.Nachname = true;
        this.error.NachnameText = "Bitte tragen Sie einen Nachnamen ein";
        this.errors = 1;
      } else {
        this.error.Nachname = false;
      }

      if (this.bewerbung.Email1 == "") {
        this.error.Email1 = true;
        this.error.Email1Text = "Bitte tragen Sie eine E-mail Adresse ein";
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      if (this.bewerbung.Datenschutz == false) {
        this.error.Datenschutz = true;
        this.error.DatenschutzText =
          "Bitte bestätigen Sie die Kenntnisnahme der Datenschutzerklärung";
        this.errors = 1;
      } else {
        this.error.Datenschutz = false;
      }
      console.log(validEmail(this.bewerbung.Email1));

      if (validEmail(this.bewerbung.Email1) == false) {
        this.error.Email1 = true;
        this.error.Email1Text = "Bitte tragen Sie eine gültige E-mail Adresse ein";
        this.errors = 1;
      } else {
        this.error.Email1 = false;
      }

      console.log(this.errors);
      if (this.errors == 0) {
        console.log("keine Fehler");
        this.sendmail();
        return true;
      }
    },
    clearscreen() {
      this.bewerbung.Stelle = "";
      this.bewerbung.Vorname = "";
      this.bewerbung.Nachname = "";
      this.bewerbung.Email1 = "";
      this.bewerbung.Strasse = "";
      this.bewerbung.Hausnummer = "";
      this.bewerbung.PLZ = "";
      this.bewerbung.Stadt = "";
      this.bewerbung.Telefon = "";
      this.bewerbung.andere = true;
      this.bewerbung.Datenschutz = false;
      this.bewerbung.Lebenslauf = "";
      this.bewerbung.Anschreiben = "";
      this.bewerbung.Anschreiben_temp = "";
      this.error.LebenslaufText = "";

      this.$refs.lebenslauffile.value = "";
      this.$refs.anschreibenfile.value = "";
    },
    sendmail: function () {
      let payload = this.bewerbung;

      payload.empfaenger = "l.hertwig@hertwig-cnc.de";
      // payload.empfaenger= "joerg@pillar.de",
      payload.Grund = "Bewerbung";

      let mail = this.mail;
      axios
        .post("https://pillar.de/mailrelay/post_attachment.php", payload, axiosConfig)
        .then((response) => {
          console.log("success", response.data);
          mail.gesendet =
            "Wir bedanken uns für Ihre Bewerbung. Ihre Kontaktdaten wurden erfolgreich versendet";
          this.clearscreen();
        })
        .catch((error) => {
          console.log(error.response);
          mail.gesendet =
            "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
        });
    },
    uploadLebenslauf() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.lebenslauffile.files.length; i++) {
        let file = this.$refs.lebenslauffile.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Lebenslauf = response.data.file_org;
          bewerbung.Lebenslauf_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
    uploadAnschreiben() {
      let bewerbung = this.bewerbung;
      let formData = new FormData();
      for (var i = 0; i < this.$refs.anschreibenfile.files.length; i++) {
        let file = this.$refs.anschreibenfile.files[i];
        const t = Math.round(+new Date() / 1000); // unix timestamp
        console.log(t);
        formData.append("uploadfile", file);
        console.log(formData);
      }

      axios
        .post("https://pillar.de/mailrelay/fileupload.php", formData, axiosConfig)
        .then(function (response) {
          console.log(response.data);
          bewerbung.Anschreiben = response.data.file_org;
          bewerbung.Anschreiben_temp = response.data.filePath;
        })
        .catch(function () {
          //		$('#BitteWarten').modal('toggle');
        });
    },
  },
  computed: {},
  created() {
    this.show = true;
  },
};
</script>

<style scoped></style>
